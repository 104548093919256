import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { useOnScreen } from '../../../hooks/useOnScreen';

interface AccordionProps {
  title: ReactNode | string;
  active?: boolean;
  titleClassName?: string;
  disabled?: boolean;
  highlightedActiveStyle?: boolean;
  onChange?: (open: boolean) => void;
}

const AccordionContext = createContext({ updateParentHeight: (_?: number): void => {} });
export const useAccordionContext = () => useContext(AccordionContext);

export const Accordion: FCWithChildren<AccordionProps> = (props) => {
  const { title, children, active = false, titleClassName, onChange, disabled, highlightedActiveStyle } = props;
  const contentSpace = useRef<HTMLDivElement>(null);
  const [activeInternal, setActiveInternal] = useState(active);
  const onScreen = useOnScreen(contentSpace);
  const prevHeight = useRef(0);
  const [rotate, setRotate] = useState(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
  const { updateParentHeight } = useAccordionContext();

  useEffect(() => {
    onChange && onChange(activeInternal);
  }, [activeInternal, onChange]);

  // Get the height that we want the container to be
  const getSetHeight = useCallback(() => parseInt(contentSpace.current?.style.minHeight.replace('px', '') || '0', 10), []);
  // Get the height it is currently, will be off during an animation!
  const getScrollHeight = useCallback(() => contentSpace?.current?.scrollHeight ?? 0, []);

  const setHeight = useCallback(
    (value: number | 'auto') => {
      const content = contentSpace.current;
      if (!content) return;

      content.style.minHeight = value === 'auto' ? 'auto' : `${value}px`;
      content.style.maxHeight = value === 'auto' ? 'auto' : `${value}px`;

      if (value !== 'auto') {
        const diff = value - prevHeight.current;

        // Tell the parent acordion to change height
        updateParentHeight(diff);
        prevHeight.current = value;
      }
    },
    [updateParentHeight],
  );

  const setAnimateHeight = useCallback((on: boolean) => {
    const content = contentSpace.current;
    if (!content) return;

    content.style.transitionProperty = on ? 'min-height,max-height' : '';
    content.style.transitionTimingFunction = on ? 'ease-in-out' : '';
    content.style.transitionDuration = on ? '700ms' : '';
  }, []);

  // Update ActiveInternal when the prop changes.
  useEffect(() => {
    setActiveInternal(active);
    setAnimateHeight(false);

    setHeight(active ? getSetHeight() : 0);
  }, [active, getScrollHeight, getSetHeight, setAnimateHeight, setHeight]);

  const toggleAccordion = useCallback(() => {
    setActiveInternal((prev) => !prev);
    setAnimateHeight(true);
    setHeight(activeInternal ? getScrollHeight() : 0);
    setRotate(!activeInternal ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
  }, [activeInternal, getScrollHeight, setAnimateHeight, setHeight]);

  useEffect(() => {
    onScreen && setHeight(activeInternal ? getScrollHeight() : 0);
  }, [onScreen, children, setHeight, getScrollHeight, activeInternal]);

  // Called by a child of the content, whenever we need to update our height
  const updateHeightFromChild = useCallback(
    (diff: number | undefined) => {
      if (diff !== undefined) {
        setHeight(getSetHeight() + diff);
      } else {
        const before = getSetHeight();
        setAnimateHeight(false);
        setHeight('auto');
        const target = getScrollHeight();
        setHeight(before);

        setTimeout(() => {
          setAnimateHeight(true);
          setHeight(target);
        }, 10);
      }
    },
    [getScrollHeight, getSetHeight, setAnimateHeight, setHeight],
  );

  const contextValues = useMemo(() => ({ updateParentHeight: updateHeightFromChild }), [updateHeightFromChild]);

  return (
    <div className={`my-2 flex flex-col ${activeInternal && highlightedActiveStyle ? 'shadow-lg' : ''}`}>
      <div
        className={`${
          disabled ? 'opacity-60' : ''
        } flex min-h-10 items-center justify-between px-4 font-medium transition-colors duration-300 ${titleClassName} ${
          highlightedActiveStyle ? (activeInternal ? 'bg-gray-5' : !disabled ? 'hover:bg-gray-5 cursor-pointer' : '') : ''
        } max-w-full`}
        onClick={disabled ? undefined : toggleAccordion}
      >
        {title}
        <ChevronIcon type={ChevronType.UP} className={`${disabled ? 'pointer-events-none ' : 'cursor-pointer'} ${rotate} inline-block h-6 w-6`} />
      </div>
      <div ref={contentSpace} className={`overflow-hidden border-t pt-2`}>
        <div className="px-4 pb-4">
          <AccordionContext.Provider value={contextValues}>{children}</AccordionContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
